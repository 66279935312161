var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$templates$find$ha, _vm$templates, _vm$templates$find;
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "pb-4",
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "title"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "title.".concat(k),
                    "name": "".concat(i, " Title"),
                    "rules": "required"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var errors = _ref2.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Title")
                        }
                      }, [_c('b-form-input', {
                        attrs: {
                          "placeholder": "".concat(i, " Title")
                        },
                        model: {
                          value: _vm.form.title[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.title, k, $$v);
                          },
                          expression: "form.title[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "vid": "parent_id",
            "name": "Parent"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Parent"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "label": "title",
                  "options": _vm.parents,
                  "reduce": function reduce(item) {
                    return item.id;
                  }
                },
                model: {
                  value: _vm.form.parent_id,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "parent_id", $$v);
                  },
                  expression: "form.parent_id"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "vid": "template",
            "name": "Template"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Template"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "label": "value",
                  "options": _vm.templates,
                  "reduce": function reduce(item) {
                    return item.id;
                  }
                },
                model: {
                  value: _vm.form.template,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "template", $$v);
                  },
                  expression: "form.template"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), ((_vm$templates$find$ha = (_vm$templates = _vm.templates) === null || _vm$templates === void 0 ? void 0 : (_vm$templates$find = _vm$templates.find(function (e) {
          return e.id === _vm.form.template;
        })) === null || _vm$templates$find === void 0 ? void 0 : _vm$templates$find.has_feature_image) !== null && _vm$templates$find$ha !== void 0 ? _vm$templates$find$ha : false) ? _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "vid": "options.feature_image",
            "name": "options.feature image",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Feature Image"
                }
              }, [_c('ImageUpload', {
                attrs: {
                  "label": '',
                  "hint": "Image size should be at least 1200x800px (16:9) and less than 2mb"
                },
                model: {
                  value: _vm.form.options.feature_image,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.options, "feature_image", $$v);
                  },
                  expression: "form.options.feature_image"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "body"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "body.".concat(k),
                    "name": "".concat(i, " Body")
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref6) {
                      var errors = _ref6.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Body")
                        }
                      }, [_c('editor', {
                        attrs: {
                          "api-key": _vm.apiKey,
                          "init": _vm.tinyConfig
                        },
                        model: {
                          value: _vm.form.body[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.body, k, $$v);
                          },
                          expression: "form.body[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('hr')]), _vm._l(_vm.form.fields, function (item, index) {
          return [item.type === 'images' ? _c('ImageRep', {
            key: "imageRep_".concat(index),
            attrs: {
              "languages": _vm.languages,
              "form-errors": _vm.formErrors
            },
            scopedSlots: _vm._u([{
              key: "delete_images",
              fn: function fn() {
                return [_c('svg', {
                  staticClass: "delete-icon",
                  attrs: {
                    "xmlns": "http://www.w3.org/2000/svg",
                    "viewBox": "0 0 448 512"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeField(index);
                    }
                  }
                }, [_c('path', {
                  attrs: {
                    "d": "M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"
                  }
                })])];
              },
              proxy: true
            }, {
              key: "b_dropdown",
              fn: function fn() {
                return [_c('v-select', {
                  attrs: {
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "label": "title",
                    "options": _vm.styles,
                    "reduce": function reduce(item) {
                      return item.style;
                    },
                    "placeholder": "Style Image",
                    "multiple": true
                  },
                  model: {
                    value: _vm.form.fields[index].style,
                    callback: function callback($$v) {
                      _vm.$set(_vm.form.fields[index], "style", $$v);
                    },
                    expression: "form.fields[index].style"
                  }
                })];
              },
              proxy: true
            }], null, true),
            model: {
              value: _vm.form.fields[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.fields, index, $$v);
              },
              expression: "form.fields[index]"
            }
          }) : _vm._e(), item.type === 'titleDetails' || item.type === 'titleDetailsWithBackground' || item.type === 'faq' ? _c('TitleDetails', {
            key: "titleDetails_".concat(index),
            attrs: {
              "languages": _vm.languages,
              "form-errors": _vm.formErrors
            },
            scopedSlots: _vm._u([{
              key: "delete_titleDetails",
              fn: function fn() {
                return [_c('b-button', {
                  attrs: {
                    "variant": "outline-danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeField(index);
                    }
                  }
                }, [_c('feather-icon', {
                  staticClass: "mr-25",
                  attrs: {
                    "icon": "XIcon"
                  }
                }), _c('span', [_vm._v("Delete")])], 1)];
              },
              proxy: true
            }, item.type === 'titleDetailsWithBackground' ? {
              key: "style_box",
              fn: function fn() {
                return [_c('div', {
                  staticClass: "custom-box-colors"
                }, [_c('div', [_c('label', {
                  staticClass: "d-block",
                  attrs: {
                    "for": "styleTitle"
                  }
                }, [_vm._v("Title Color")]), _c('b-form-input', {
                  staticClass: "d-block w-100",
                  attrs: {
                    "type": "color",
                    "value": "#ffffff",
                    "id": "styleTitle"
                  },
                  model: {
                    value: _vm.form.fields[index].styleTitle,
                    callback: function callback($$v) {
                      _vm.$set(_vm.form.fields[index], "styleTitle", $$v);
                    },
                    expression: "form.fields[index].styleTitle"
                  }
                })], 1), _c('div', [_c('label', {
                  staticClass: "d-block",
                  attrs: {
                    "for": "styleDetails"
                  }
                }, [_vm._v("Details Color")]), _c('b-form-input', {
                  staticClass: "d-block w-100",
                  attrs: {
                    "type": "color",
                    "value": "#ffffff",
                    "id": "styleDetails"
                  },
                  model: {
                    value: _vm.form.fields[index].styleDetails,
                    callback: function callback($$v) {
                      _vm.$set(_vm.form.fields[index], "styleDetails", $$v);
                    },
                    expression: "form.fields[index].styleDetails"
                  }
                })], 1), _c('div', [_c('label', {
                  staticClass: "d-block",
                  attrs: {
                    "for": "styleBox"
                  }
                }, [_vm._v("Box Background")]), _c('b-form-input', {
                  staticClass: "d-block w-100",
                  attrs: {
                    "type": "color",
                    "value": "#1B7BB9",
                    "id": "styleBox"
                  },
                  model: {
                    value: _vm.form.fields[index].styleBox,
                    callback: function callback($$v) {
                      _vm.$set(_vm.form.fields[index], "styleBox", $$v);
                    },
                    expression: "form.fields[index].styleBox"
                  }
                })], 1)])];
              },
              proxy: true
            } : null], null, true),
            model: {
              value: _vm.form.fields[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.fields, index, $$v);
              },
              expression: "form.fields[index]"
            }
          }) : _vm._e(), item.type === 'imageEditor' ? _c('ImageEditor', {
            key: "imageEditor_".concat(index),
            attrs: {
              "languages": _vm.languages,
              "form-errors": _vm.formErrors
            },
            scopedSlots: _vm._u([{
              key: "delete_imageEditor",
              fn: function fn() {
                return [_c('b-button', {
                  attrs: {
                    "variant": "outline-danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeField(index);
                    }
                  }
                }, [_c('feather-icon', {
                  staticClass: "mr-25",
                  attrs: {
                    "icon": "XIcon"
                  }
                }), _c('span', [_vm._v("Delete")])], 1)];
              },
              proxy: true
            }, {
              key: "b_dropdown",
              fn: function fn() {
                return [_c('v-select', {
                  attrs: {
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "label": "title",
                    "options": _vm.styles,
                    "reduce": function reduce(item) {
                      return item.style;
                    },
                    "placeholder": "Style Image",
                    "multiple": true
                  },
                  model: {
                    value: _vm.form.fields[index].style,
                    callback: function callback($$v) {
                      _vm.$set(_vm.form.fields[index], "style", $$v);
                    },
                    expression: "form.fields[index].style"
                  }
                })];
              },
              proxy: true
            }], null, true),
            model: {
              value: _vm.form.fields[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.fields, index, $$v);
              },
              expression: "form.fields[index]"
            }
          }) : _vm._e(), item.type === 'files' ? _c('FileRep', {
            key: "fileRep_".concat(index),
            scopedSlots: _vm._u([{
              key: "delete_fileRep",
              fn: function fn() {
                return [_c('b-button', {
                  staticClass: "btn-icon ml-1",
                  attrs: {
                    "variant": "danger"
                  },
                  on: {
                    "click": function click($event) {
                      $event.preventDefault();
                      return _vm.removeField(index);
                    }
                  }
                }, [_c('feather-icon', {
                  attrs: {
                    "icon": "MinusIcon"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true),
            model: {
              value: _vm.form.fields[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.fields, index, $$v);
              },
              expression: "form.fields[index]"
            }
          }) : _vm._e(), item.type === 'editor' ? _c('SingleEditor', {
            key: "editor_".concat(index),
            attrs: {
              "languages": _vm.languages,
              "form-errors": _vm.formErrors
            },
            model: {
              value: _vm.form.fields[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.fields, index, $$v);
              },
              expression: "form.fields[index]"
            }
          }) : _vm._e()];
        }), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-end flex-column"
        }, [_c('b-dropdown', {
          attrs: {
            "text": "Add Field",
            "variant": "gradient-primary",
            "right": ""
          }
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addField('titleDetails');
            }
          }
        }, [_vm._v(" Title & Details ")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addField('titleDetailsWithBackground');
            }
          }
        }, [_vm._v(" Title & Details With Background ")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addField('faq');
            }
          }
        }, [_vm._v(" FAQ ")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addField('editor');
            }
          }
        }, [_vm._v(" Editor ")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addField('images');
            }
          }
        }, [_vm._v(" Images ")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addField('imageEditor');
            }
          }
        }, [_vm._v(" Image & Editor ")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addField('files');
            }
          }
        }, [_vm._v(" File ")])], 1)], 1)]), _c('b-col', {
          staticClass: "pt-1",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" Status ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "active",
            "unchecked-value": "inactive",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12 text-right"
          }
        }, [_c('LoadingButton'), _c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 2)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }