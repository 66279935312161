<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          class="pb-4"
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="`${i} Title`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Title`">
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="`${i} Title`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                vid="parent_id"
                name="Parent"
              >
                <b-form-group
                  label="Parent"
                >
                  <v-select
                    v-model="form.parent_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="parents"
                    :reduce="item => item.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                vid="template"
                name="Template"
              >
                <b-form-group
                  label="Template"
                >
                  <v-select
                    v-model="form.template"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="templates"
                    :reduce="item => item.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="templates?.find(e => e.id === form.template)?.has_feature_image ?? false"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="options.feature_image"
                name="options.feature image"
                rules="required"
              >
                <b-form-group label="Feature Image">
                  <ImageUpload
                    v-model="form.options.feature_image"
                    :label="''"
                    hint="Image size should be at least 1200x800px (16:9) and less than 2mb"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="body"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`body.${k}`"
                    :name="`${i} Body`"
                  >
                    <b-form-group :label="`${i} Body`">
                      <editor
                        v-model="form.body[k]"
                        :api-key="apiKey"
                        :init="tinyConfig"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>

            <template
              v-for="(item, index) in form.fields"
            >
              <ImageRep
                v-if="item.type === 'images'"
                :key="`imageRep_${index}`"
                v-model="form.fields[index]"
                :languages="languages"
                :form-errors="formErrors"
              >
                <template #delete_images>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="delete-icon"
                    @click="removeField(index)"
                  >
                    <path
                      d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"
                    />
                  </svg>
                </template>
                <template #b_dropdown>

                  <v-select
                    v-model="form.fields[index].style"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="styles"
                    :reduce="item => item.style"
                    placeholder="Style Image"
                    :multiple="true"
                  />
                </template>
              </ImageRep>
              <TitleDetails
                v-if="item.type === 'titleDetails' || item.type === 'titleDetailsWithBackground' || item.type === 'faq'"
                :key="`titleDetails_${index}`"
                v-model="form.fields[index]"
                :languages="languages"
                :form-errors="formErrors"
              >
                <template #delete_titleDetails>
                  <b-button
                    variant="outline-danger"
                    @click="removeField(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </template>
                <template v-if="item.type === 'titleDetailsWithBackground'" #style_box>
                  <div class="custom-box-colors">
                    <div>
                      <label for="styleTitle" class="d-block">Title Color</label>
                      <b-form-input type="color" value="#ffffff" id="styleTitle" class="d-block w-100" v-model="form.fields[index].styleTitle"></b-form-input>
                    </div>
                    <div>
                      <label for="styleDetails" class="d-block">Details Color</label>
                      <b-form-input type="color" value="#ffffff" id="styleDetails" class="d-block w-100" v-model="form.fields[index].styleDetails"></b-form-input>
                    </div>
                    <div>
                      <label for="styleBox" class="d-block">Box Background</label>
                      <b-form-input type="color" value="#1B7BB9" id="styleBox" class="d-block w-100" v-model="form.fields[index].styleBox"></b-form-input>
                    </div>
                  </div>
                </template>
              </TitleDetails>
              <ImageEditor
                v-if="item.type === 'imageEditor'"
                :key="`imageEditor_${index}`"
                v-model="form.fields[index]"
                :languages="languages"
                :form-errors="formErrors"
              >
                <template #delete_imageEditor>
                  <b-button
                    variant="outline-danger"
                    @click="removeField(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </template>
                <template #b_dropdown>
                  <v-select
                    v-model="form.fields[index].style"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="styles"
                    :reduce="item => item.style"
                    placeholder="Style Image"
                    :multiple="true"
                  />
                </template>
              </ImageEditor>
              <FileRep
                v-if="item.type === 'files'"
                :key="`fileRep_${index}`"
                v-model="form.fields[index]"
              >
                <template #delete_fileRep>
                  <b-button
                    variant="danger"
                    class="btn-icon ml-1"
                    @click.prevent="removeField(index)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </template>
              </FileRep>
              <SingleEditor
                v-if="item.type === 'editor'"
                :key="`editor_${index}`"
                v-model="form.fields[index]"
                :languages="languages"
                :form-errors="formErrors"
              />
            </template>

            <b-col cols="12">
              <div class="d-flex align-items-end flex-column">
                <b-dropdown
                  text="Add Field"
                  variant="gradient-primary"
                  right
                >
                  <b-dropdown-item @click.prevent="addField('titleDetails')">
                    Title & Details
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="addField('titleDetailsWithBackground')">
                    Title & Details With Background
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="addField('faq')">
                    FAQ
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="addField('editor')">
                    Editor
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="addField('images')">
                    Images
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="addField('imageEditor')">
                    Image & Editor
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="addField('files')">
                    File
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>

            <!-- checkbox -->
            <b-col
              cols="12"
              class="pt-1"
            >
              <b-card-text class="mb-0">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import TinyService from '@/services/tiny.service'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import formMixin from '@/mixins/formMixin'
import _ from 'lodash'

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition, formMixin],
  data() {
    return {
      styles: [
        { style: { width: '100%' }, title: 'Full Width' },
        { style: { width: 'auto' }, title: 'Width Auto' },
        { style: { 'max-height': 'auto' }, title: 'Height Auto' },
        { style: { 'max-width': '400px' }, title: 'Max Width (400px)' },
        { style: { margin: '0 auto' }, title: 'Center Image' },
        { style: { 'margin-left': 'auto' }, title: 'Left Position' },
        { style: { 'margin-right': 'auto' }, title: 'Right Position' },
      ],
      imagePreview: null,
      formErrors: null,
      form: {
        title: {
          en: null,
          ar: null,
        },
        body: {
          en: null,
          ar: null,
        },
        status: 'active',
        parent_id: null,
        template: null,
        fields: [],
        options: {
          feature_image: null,
        },
      },
      initFields: {
        images: [{
          type: 'images',
          image: null,
          style: [],
        }],
        titleDetails: [{
          type: 'titleDetails',
          title: {
            en: null,
            ar: null,
          },
          details: {
            en: null,
            ar: null,
          },
        }],
        titleDetailsWithBackground: [{
          type: 'titleDetailsWithBackground',
          title: {
            en: null,
            ar: null,
          },
          details: {
            en: null,
            ar: null,
          },
          styleBox: '#1B7BB9',
          styleTitle: '#ffffff',
          styleDetails: '#ffffff',
        }],
        faq: [{
          type: 'faq',
          title: {
            en: null,
            ar: null,
          },
          details: {
            en: null,
            ar: null,
          },
        }],
        imageEditor: [{
          type: 'imageEditor',
          image: null,
          details: {
            en: null,
            ar: null,
          },
        }],
        editor: [{
          type: 'editor',
          details: {
            en: null,
            ar: null,
          },
        }],
        files: [{
          type: 'files',
          name: null,
          title: null,
          file: null,
          size: null,
        }],
      },
      parents: [],
      templates: [],
    }
  },
  computed: {
    apiKey() {
      // eslint-disable-next-line import/no-named-as-default-member
      return TinyService.apiKey()
    },
    tinyConfig() {
      // eslint-disable-next-line import/no-named-as-default-member
      return TinyService.getConfig()
    },
  },
  created() {
    this.loadPages()
    this.loadConstants()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    loadPages() {
      this.axios.get('/pages/list')
        .then(res => {
          this.parents = res.data.data
        })
    },
    loadConstants() {
      this.axios.get(`${this.resource}/constants`)
        .then(res => {
          this.templates = res.data.data.templates
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = _.pick(data, [
            'status', 'parent_id',
          ])
          this.form = {
            ...this.form,
            title: data.title_value,
            body: data.body_value,
            template: data.template,
            fields: data.fields ?? [],
            options: data.options ?? { feature_image: null },
          }
        })
    },
    addField(field) {
      const $field = _.cloneDeep(this.initFields[field][0])
      this.form.fields.push($field)
    },
    removeField(index) {
      this.form.fields.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
[dir] .dark-layout .custom-box-colors {
  border-color: #3b4253;
}
.custom-box-colors {
  width: 100%;
  margin:5px 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebe9f1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.custom-box-colors > div {
  width: 120px;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
